import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
//6.引入导出的路由
import router from './router/index'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import moment from "moment";
import VueMeta from 'vue-meta';

import Carousel3d from 'vue-carousel-3d'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta)

Vue.use(Carousel3d)

Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.use(ElementUI)

Vue.filter("dateFormat", function (dateStr, pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dateStr).format(pattern);
});
Vue.filter("dateFormats", function (dateStr, pattern = "MM/DD") {
  return moment(dateStr).format(pattern);
});
Vue.filter("dateFormatY", function (dateStr, pattern = "YYYY-MM-DD") {
  return moment(dateStr).format(pattern);
});
Vue.prototype.$axios = axios;  //在Vue的原型上添加$axios方法
Vue.config.productionTip = false;
Vue.prototype.moment = moment;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
