<template>
  <div>
    <div class="header-show" v-if="navShow">
      <div class="logo" @click="goHome">
        <img src="@/assets/h-logo.png" alt="" class="logo-img" />
      </div>
      <div class="items">
        <div
          :class="page === 'home' ? 'item item-active' : 'item'"
          @click="goHome"
        >
          首页
        </div>
        <div
          :class="page === 'selectedCases' ? 'item item-active' : 'item'"
          @click="goCase('0')"
        >
          精选案例
        </div>
        <div
          :class="page === 'designTeam' ? 'item item-active' : 'item'"
          class="item"
          @click="goDesign"
        >
          设计团队
        </div>
        <div
          :class="page === 'designTeam' ? 'item item-active' : 'item'"
          class="item"
          @click="goBussinessTeam"
        >
          商务团队
        </div>
        <div
          :class="page === 'serviceProcess' ? 'item item-active' : 'item'"
          class="item"
          @click="goService"
        >
          服务流程
        </div>
        <el-dropdown>
          <div class="item">
            关于我们
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown">
            <el-dropdown-item class="dropdown-items">
              <div @click="goJoin">加入我们</div>
            </el-dropdown-item>
            <el-dropdown-item class="dropdown-items">
              <div @click="goBrand">品牌故事</div>
            </el-dropdown-item>
            <el-dropdown-item class="dropdown-items">
              <div @click="goContact">联系我们</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="item" @click="goJoin">关于我们</div> -->
        <div
          :class="page === 'newsCenter' ? 'item item-active' : 'item'"
          class="item"
          @click="goNews"
        >
          新闻中心
        </div>
        <div class="item-img">
          <img
            src="@/assets/home-more1.png"
            alt=""
            class="more-img"
            @click="showHead"
          />
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="hj-carousel" @mouseenter="stop" @mouseleave="go">
      <transition-group name="list" tag="ul" ref="container">
        <li
          v-for="(item, index) in bannerList"
          :key="index"
          class="list-item"
          v-show="index === currentIndex"
          @webkitTransitionend="carouseTransition"
          :style="{ backgroundImage: `url(${item.url})` }"
        >
          <!-- 轮播图文本介绍 -->
          <div class="wrapper intro-box">
            <div class="header" v-if="headShow">
              <div class="logo" @click="goHome">
                <img src="@/assets/h-logo.png" alt="" class="logo-img" />
              </div>
              <div class="items">
                <div class="item" @click="goHome">首页</div>
                <div class="item" @click="goCase('0')">精选案例</div>
                <div class="item" @click="goDesign">设计团队</div>
                <div class="item" @click="goBussinessTeam">商务团队</div>
                <div class="item" @click="goService">服务流程</div>
                <el-dropdown>
                  <div class="item">
                    关于我们
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown" class="dropdown">
                    <el-dropdown-item class="dropdown-items">
                      <div @click="goJoin">加入我们</div>
                    </el-dropdown-item>
                    <el-dropdown-item class="dropdown-items">
                      <div @click="goBrand">品牌故事</div>
                    </el-dropdown-item>
                    <el-dropdown-item class="dropdown-items">
                      <div @click="goContact">联系我们</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <div class="item" @click="goJoin">关于我们</div> -->
                <div class="item" @click="goNews">新闻中心</div>
                <div class="item-img">
                  <img
                    src="@/assets/home-more1.png"
                    alt=""
                    class="more-img"
                    @click="showHead"
                  />
                </div>
              </div>
            </div>
            <div class="service-intro">
              <div class="top" @click="goHome">
                <img src="@/assets/home-logo.png" alt="" class="logo-img" />
                <div class="items" v-if="homeNav">
                  <div class="item" @click="goHome">首页</div>
                  <div class="item" @click="goCase('0')">精选案例</div>
                  <div class="item" @click="goDesign">设计团队</div>
                  <div class="item" @click="goBussinessTeam">商务团队</div>
                  <div class="item" @click="goService">服务流程</div>
                  <el-dropdown>
                    <div class="item">
                      关于我们
                      <i class="el-icon-arrow-down"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown" class="dropdown">
                      <el-dropdown-item class="dropdown-items">
                        <div @click="goJoin">加入我们</div>
                      </el-dropdown-item>
                      <el-dropdown-item class="dropdown-items">
                        <div @click="goBrand">品牌故事</div>
                      </el-dropdown-item>
                      <el-dropdown-item class="dropdown-items">
                        <div @click="goContact">联系我们</div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <!-- <div class="item" @click="goJoin">关于我们</div> -->
                  <div class="item" @click="goNews">新闻中心</div>
                  <div>
                    <img
                      src="@/assets/home-more.png"
                      alt=""
                      class="more-img"
                      @click="showHead"
                    />
                  </div>
                </div>
                <div v-else>
                  <img
                    src="@/assets/home-more.png"
                    alt=""
                    class="more-img"
                    @click="showHead"
                  />
                </div>
              </div>
              <!-- <h2>{{ item.fileName }}</h2> -->
              <!-- <p>{{ item.intro_one }}</p>
              <p>{{ item.intro_two }}</p> -->
              <!-- <router-link class="intro-btn" :to="item.url">查看</router-link> -->
            </div>
          </div>
        </li>
      </transition-group>
      <!-- 小圆点 -->
      <ol class="circles-items">
        <li
          v-for="(item, index) in bannerList.length"
          :key="index"
          :class="{ active: index === currentIndex }"
          @click="change(index)"
        ></li>
      </ol>
    </div>
    <div class="home-content">
      <!-- 第二屏 -->
      <div class="second">
        <div class="second-content">
          <!-- 左边 -->
          <div class="second-left">
            <div class="second-who">WHO WE ARE？</div>
            <div class="second-tit">
              甲第设计
              <span class="little">—中国人的庭院美学</span>
            </div>
            <div class="second-line"></div>
            <div class="contents">
              五千年的东方历史脉络，全都蕴涵在一座庭院里。
            </div>
            <div class="contents">
              它以历史为骨，山水为髓，文化为魂，光影为肌，兼容并蓄。在无限的时空里，生成一方小院子，揽闲情于墙院，锁风景于院内。一步藏乾坤，一踏生雅趣，可以在这里宴明月清风作伴，可以携花鸟鱼虫同饮，又可以“纳千顷之汪洋，收四时之烂漫”。
            </div>

            <div class="contents">
              无论是庭院深深锁清秋还是清风杨柳芊，庭院四合间。无论是春分夏至，秋分冬至，都会春花艳，夏荷素，秋枫起，冬雪落，时令意象与庭院意象互相重叠，互相融合，从而天人合一。
            </div>
            <div class="contents">
              愿有一庭院，可以慰风尘，常有庭上客，一坐尽生春。
            </div>
            <div class="more" @click="goBrand">
              <div class="more-tit">查看更多</div>
              <img src="@/assets/ic_jiantou.png" alt="" class="more-img" />
            </div>
          </div>
          <!-- 右边 -->
          <div class="second-right">
            <!-- <div class="right-content" @click="opensVideo">
              <img src="@/assets/design-videobg.png" alt="" class="right-img" />
              <img src="@/assets/bof.png" alt="" class="right-bf" />
            </div> -->
            <div class="right-content">
              <home-video></home-video>
            </div>
            <div class="rightList">
              <div class="rightItmes">
                <div class="year">
                  2014
                  <span class="years">年</span>
                </div>
                <div class="des">成立时间</div>
              </div>
              <div class="rightItmes">
                <div class="year">
                  98
                  <span class="years">万平米+</span>
                </div>
                <div class="des">设计总面积</div>
              </div>
              <div class="rightItmes">
                <div class="year">
                  <!-- 最简单的案例，from是开始值，to是结束值 -->
                  <animate-number
                    ref="myNums"
                    mode="manual"
                    from="0"
                    to="10000"
                  ></animate-number>
                  <span class="years">人+</span>
                </div>
                <div class="des">服务客户</div>
              </div>
              <div class="rightItmes">
                <div class="year">
                  <animate-number
                    ref="myNum"
                    mode="manual"
                    from="0"
                    to="200"
                  ></animate-number>
                  <span class="years">人</span>
                </div>
                <div class="des">设计师人数</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第三屏 -->
      <div class="third">
        <div class="design">DESIGN CASE</div>
        <div class="design-tit">设计案例</div>
        <div class="third-content">
          <div class="left">
            <div class="box" @click="goCase('1')">
              <img
                src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352303000"
                alt=""
                class="box_img"
              />
              <div class="inside">
                <div class="word">中式风格</div>
                <div class="line"></div>
              </div>
            </div>
            <div class="box bot" @click="goCase('2')">
              <img
                src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352306000"
                alt=""
                class="box_img"
              />
              <div class="inside">
                <div class="word">新中风格</div>
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div class="wbox middle" @click="goCase('3')">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352308000"
              alt=""
              class="wbox_img"
            />
            <div class="winside">
              <div class="wword">现代风格</div>
              <div class="wline"></div>
            </div>
          </div>
          <div class="right-con">
            <div class="hbox tops" @click="goCase('6')">
              <img
                src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352314000"
                alt=""
                class="hbox_img"
              />
              <div class="hinside">
                <div class="hword">禅意风格</div>
                <div class="hline"></div>
              </div>
            </div>

            <div class="bot">
              <div class="box bot-left" @click="goCase('4')">
                <img
                  src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352312000"
                  alt=""
                  class="box_img"
                />
                <div class="inside">
                  <div class="word">欧式风格</div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="box bot-right" @click="goCase('5')">
                <img
                  src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352311000"
                  alt=""
                  class="box_img"
                />
                <div class="inside">
                  <div class="word">田园</div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第四屏 -->
      <div class="fourth">
        <div class="fourth-con">
          <div class="fourth-left">
            <div class="video">video</div>
            <div class="case">
              <div class="top">case</div>
              <div class="tit">案例视频</div>
            </div>
          </div>

          <div class="fourth-right">
            <div v-for="(video, index) in videoList" :key="index">
              <div v-if="index === videoIndex">
                <!-- <img :src="video.thumbnail" alt="" class="right-img" />
                <img src="@/assets/bof.png" alt="" class="right-bf" /> -->
                <video
                  class="videoItems"
                  width="921"
                  height="490"
                  controls
                  :controlslist="videolists.controlslist"
                  :poster="video.thumbnail"
                  style="object-fit: cover"
                  loop
                >
                  <source :src="video.urlAccess" />
                </video>
                <div class="explan">{{ video.explan }}</div>
              </div>
            </div>
            <div class="video-bot">
              <div @click="videoPrev" class="left">
                <img src="@/assets/ic_left.png" alt="" class="left-img" />
              </div>
              <div class="proess">
                {{ videoIndex + 1 }}/{{ videoList.length }}
              </div>
              <div @click="videoNext" class="right">
                <img src="@/assets/ic_right.png" alt="" class="right-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第五屏 -->
      <div class="fifth">
        <div class="new-tit">NEW MEDIA PLATFORM</div>
        <div class="new-media">新媒体平台</div>
        <div class="jd-des">
          甲第在新媒体平台的多年深耕运营，现在各大平台粉丝量已经达到行业的NO.1
        </div>
        <div class="contents">
          <!-- 左边图片 -->
          <div class="media-left">
            <!-- 上 -->
            <div class="media">
              <!-- 抖音 -->
              <div>
                <!-- <img src="@/assets/dy.png" alt="" class="media-pic" /> -->
                <div class="box">
                  <img
                    src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352338000"
                    alt=""
                    class="box_img"
                  />
                  <div class="inside">
                    <!-- <img src="@/assets/mohu.png" alt="" /> -->
                    <div class="word">
                      <div class="word-tit">抖音</div>
                      <div class="word-num">800W粉丝|总获赞2000W</div>
                      <div class="word-line"></div>
                      <div class="word-content">
                        全国线上花园设计领航品牌，花园设计行业风向标
                      </div>
                    </div>
                  </div>
                </div>
                <div class="logo">
                  <img src="@/assets/dylogo.png" alt="" class="media-logo" />
                  <div class="media-des">抖音</div>
                </div>
              </div>
              <!-- 小红书 -->
              <div class="img-right">
                <div class="box">
                  <img
                    src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352345000"
                    alt=""
                    class="box_img"
                  />
                  <div class="inside">
                    <!-- <img src="@/assets/mohu.png" alt="" /> -->
                    <div class="word">
                      <div class="word-tit">小红书</div>
                      <div class="word-num">总粉丝100W|总获赞500W</div>
                      <div class="word-line"></div>
                      <div class="word-content">
                        花园布局干货知识实时分享，致力于打造更多高品质特色花园
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <img src="@/assets/xhs.png" alt="" class="media-pic" /> -->
                <div class="logo">
                  <img src="@/assets/xhslogo.png" alt="" class="media-logo" />
                  <div class="media-des">小红书</div>
                </div>
              </div>
            </div>

            <!-- 下 -->
            <div class="media">
              <!-- 微信视频号 -->
              <div>
                <div class="box">
                  <img
                    src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352343000"
                    alt=""
                    class="box_img"
                  />
                  <div class="inside">
                    <!-- <img src="@/assets/mohu.png" alt="" /> -->
                    <div class="word">
                      <div class="word-tit">微信视频号</div>
                      <div class="word-num">300W粉丝</div>
                      <div class="word-line"></div>
                      <div class="word-content">
                        用视频打开展示与沟通的窗口，让更多人看到属于中国人的庭院美学
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <img src="@/assets/wx.png" alt="" class="media-pic" /> -->
                <div class="logo">
                  <img src="@/assets/wxlogo.png" alt="" class="media-logo" />
                  <div class="media-des">微信视频号</div>
                </div>
              </div>
              <!-- 快手 -->
              <div class="img-right">
                <div class="box">
                  <img
                    src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352341000"
                    alt=""
                    class="box_img"
                  />
                  <div class="inside">
                    <!-- <img src="@/assets/mohu.png" alt="" /> -->
                    <div class="word">
                      <div class="word-tit">快手</div>
                      <div class="word-num">300W粉丝|总获赞800W</div>
                      <div class="word-line"></div>
                      <div class="word-content">
                        专注于私家庭院景观布局，在这里，你可以看到异彩纷呈的花园布局
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <img src="@/assets/dy.png" alt="" class="media-pic" /> -->
                <div class="logo">
                  <img src="@/assets/kslogo.png" alt="" class="media-logo" />
                  <div class="media-des">快手</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边年份 -->
          <div class="media-right">2014-2022</div>
        </div>
      </div>
      <!-- 第六屏 -->
      <div class="sixth">
        <div class="new-tit">DESIGN TEAM</div>
        <div class="new-media">设计团队</div>
        <div class="jd-des">
          公司有着一支由高水准优秀人才构成的精英团队，其引路人深耕花园设计行业十几年。作为中国领先的线上花园设计行业的精英团队，印证了花园由传统线下设计向线上迅速发展的趋势，累积了丰富多彩的庭院景观类设计工作经验
        </div>
        <!-- content -->
        <div v-for="(design, index) in designList" :key="index">
          <div class="design-content" v-if="index === designIndex">
            <div class="design-left">
              <div class="design-name">{{ design.name || "暂无" }}</div>
              <div class="design-work">{{ design.job || "暂无" }}</div>
              <div class="design-des">
                {{ design.vitae || "暂无" }}
              </div>
              <div class="design-bot">
                <div class="more" @click="goDesign">
                  <div class="more-tit">查看更多</div>
                  <img src="@/assets/ic_jiantou.png" alt="" class="more-img" />
                </div>
                <div class="pre-next">
                  <div @click="designPrev" class="left">
                    <img src="@/assets/ic_left.png" alt="" class="left-img" />
                  </div>
                  <div @click="designNext" class="right">
                    <img src="@/assets/ic_right.png" alt="" class="right-img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="design-right" v-if="design.designerImageVoList">
              <img
                :src="design.designerImageVoList[0].urlAccess"
                alt=""
                class="designer"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 第七屏 -->
      <div class="seventh">
        <div class="bg-img">
          <!-- <div
            class="bg-item"
            @mouseenter="entersFA"
            @mouseleave="leavesFA"
          ></div> -->
          <div class="box bg-item">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352256000"
              alt=""
              class="box_img"
            />
            <div class="inside">
              <img
                src="@/assets/ic_fangan.png"
                alt=""
                class="indide-mark-img"
              />
              <div class="word">方案最真实</div>
              <div class="words">
                <div>100%原创方案</div>
              </div>
            </div>
          </div>
          <div class="box bg-item">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352262000"
              alt=""
              class="box_img"
            />
            <div class="inside">
              <img
                src="@/assets/ic_fengge.png"
                alt=""
                class="indide-mark-img"
              />
              <div class="word">风格最全面</div>
              <div class="words">
                <div class="words-content">
                  新中，现代，中式，自然田园，欧式，美式
                  ，混搭.....好花园无需被定义
                </div>
              </div>
            </div>
          </div>
          <div class="box bg-item">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352269000"
              alt=""
              class="box_img"
            />
            <div class="inside">
              <img src="@/assets/ic_team.png" alt="" class="indide-mark-img" />
              <div class="word">团队最专业</div>
              <div class="words">
                <div>精英团队</div>
                <div>科班出身</div>
                <div>经验丰富</div>
                <div>勇于创新</div>
              </div>
            </div>
          </div>
          <div class="box bg-item">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352247000"
              alt=""
              class="box_img"
            />
            <div class="inside">
              <img src="@/assets/ic_chutu.png" alt="" class="indide-mark-img" />
              <div class="word">出图最高效</div>
              <div class="words">
                <div class="words-content">
                  凭借线上沟通能力优势，极大的缩短了设计周期
                </div>
              </div>
            </div>
          </div>
          <div class="box bg-item">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352264000"
              alt=""
              class="box_img"
            />
            <div class="inside">
              <img src="@/assets/ic_fuwu.png" alt="" class="indide-mark-img" />
              <div class="word">服务最优质</div>
              <div class="words">
                <div>首创8对1服务新模式</div>
                <div>24小时远程指导，售后无忧</div>
              </div>
            </div>
          </div>
          <div class="box bg-item">
            <img
              src="http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352267000"
              alt=""
              class="box_img"
            />
            <div class="inside">
              <img src="@/assets/ic_manyi.png" alt="" class="indide-mark-img" />
              <div class="word">客户最满意</div>
              <div class="words">
                <div>好感只有起点，没有终点</div>
              </div>
            </div>
          </div>
        </div>
        <div class="seven-content">
          <div class="seven-des">
            <div class="new-tit">WHY CHOOSE JIADI DESIGN</div>
            <div class="new-media">甲第优势</div>
            <div class="jd-des">
              完善的服务能力，为客户提供全方位一站式服务，完成客户的需求
            </div>
          </div>
          <div class="mark"></div>
        </div>
      </div>
      <!-- 第八屏 -->
      <div class="eighth">
        <div class="eighth-tit">NEWS</div>
        <div class="eighth-des">新闻资讯</div>
        <!-- content -->
        <div class="eighth-content">
          <!-- <div v-for="item in newlist" :key="item.id">
            <div class="eighth-item">
              <div class="date">{{ item.createTime | dateFormats }}</div>
              <div class="title">{{ item.title }}</div>
              <div class="lines"></div>
              <div class="detail" v-html="item.content">
              </div>
            </div>
          </div> -->
          <div v-for="item in newsList" :key="item.id">
            <div class="eighth-item" @click="goNewsDetail(item.id)">
              <div class="date">{{ item.createTime | dateFormats }}</div>
              <div class="title">{{ item.title }}</div>
              <div class="lines"></div>
              <div class="detail" v-html="item.content"></div>
            </div>
          </div>

          <!-- <div class="eighth-item">
          <div class="date">11/23</div>
          <div class="title">浪花园，深圳勒杜鹃花展宝安精品花园</div>
          <div class="lines"></div>
          <div class="detail">
            “浪花园”是一座可被回收再利用的临时性展园，位于2020深圳簕杜鹃花展的宝安展园区，占地300平方米。
          </div>
        </div>
        <div class="eighth-item">
          <div class="date">11/23</div>
          <div class="title">浪花园，深圳勒杜鹃花展宝安精品花园</div>
          <div class="lines"></div>
          <div class="detail">
            “浪花园”是一座可被回收再利用的临时性展园，位于2020深圳簕杜鹃花展的宝安展园区，占地300平方米。
          </div>
        </div> -->
        </div>
        <div class="more" @click="goNews()">
          <div class="more-tit">查看更多</div>
          <img src="@/assets/ic_jiantou.png" alt="" class="more-img" />
        </div>
      </div>
      <!-- 第九屏 -->
      <div class="ninth">
        <div class="ninth-tit">请专业顾问为您介绍</div>
        <div class="ninth-tel">留下您的联系方式，我们的设计顾问将为您服务</div>
        <div class="forms">
          <!-- <el-input v-model="user" placeholder="审批人"></el-input> -->
          <div class="forms-item">
            <input
              type="text"
              v-model="city"
              class="_input"
              placeholder="城市"
            />
            <input
              type="text"
              v-model="tel"
              class="_input"
              placeholder="手机号"
            />
          </div>
          <div class="forms-item">
            <input
              type="text"
              v-model="name"
              class="_input"
              placeholder="姓名"
            />
            <input
              type="text"
              v-model="area"
              class="_input"
              placeholder="面积"
            />
          </div>
        </div>
        <div class="sub-btn" @click="submitForms">立即报价</div>
      </div>
      <!-- 第十屏tenth -->
      <div class="tenth">
        <div class="tenth-left">
          <div class="tenth-des">
            <div class="address">
              <div class="address-tit">甲第景观设计</div>
              <div class="address-site">
                江苏地址：江苏省南京市雨花台区软件大道165号紫竹芥纳空间11楼
              </div>
              <div class="address-site">
                咨询电话：189 0516 9625 或 153 1241 5317
                <!-- 安徽地址：安徽省合肥市蜀山区绿地蓝海国际大厦C座607室 -->
              </div>
              <div class="address-site">
                邮箱：zhaobingrong@jiadi.vip
                <!-- 安徽地址：安徽省合肥市蜀山区绿地蓝海国际大厦C座607室 -->
              </div>
            </div>
            <!-- <div class="tel">
              <div class="tel-item"></div>
              <div class="tel-item">邮箱：zhaobingrong@jiadi.vip</div>
            </div> -->
            <div class="rightImg">
              <div class="attention">
                <div class="attention-tit">关注</div>
                <div class="attention-img">
                  <img src="@/assets/gzh.png" alt="" class="bot-pic" />
                </div>
                <div class="attention-des">甲第景观设计公众号</div>
              </div>
              <div class="media">
                <div class="media-img">
                  <img src="@/assets/xcx.png" alt="" class="bot-pic" />
                </div>
                <div class="media-des">甲第商城小程序</div>
              </div>
              <div class="mt-img">
                <img src="@/assets/mt.png" alt="" class="bot-pic" />
              </div>
            </div>
          </div>
          <div class="tenth-router">
            <div class="bot-logo">
              <img src="@/assets//home-logo.png" alt="" class="bot-logo-img" />
            </div>
            <div class="router-item" @click="goHome">首页</div>
            <div class="router-item" @click="goCase('0')">精选案例</div>
            <div class="router-item" @click="goDesign">设计团队</div>
            <div class="router-item" @click="goBussinessTeam">商务团队</div>
            <div class="router-item" @click="goService">服务流程</div>
            <div class="router-item" @click="goBrand">关于我们</div>
            <div class="router-item" @click="goNews">新闻中心</div>
          </div>
          <div class="beian">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              class="beian-font"
            >
              沪ICP备2022032211号-1
            </a>
          </div>
        </div>
        <div class="tenth-right">
          <!-- trigger="hover" -->
          <el-popover placement="left" width="310" trigger="hover">
            <div class="content-hover">
              <div class="content-left">
                <div class="content-tel">
                  <div class="tel">
                    <div class="head">
                      <img src="@/assets/lxwm.png" alt="" class="head-img" />
                    </div>
                    <div class="tel-tit">联系甲第</div>
                  </div>
                  <div class="tel-des">· 189-0515-7280 ·</div>
                  <div class="tel-des">· 189-0519-3710 ·</div>
                </div>
                <div class="content-time">
                  <div class="time-des">早09:00~晚06:00</div>
                  <div class="tel-des">· 199-5179-8350 ·</div>
                  <div class="tel-des">· 199-6204-1336 ·</div>
                </div>
              </div>
              <div class="content-right">
                <div class="scaner-left">
                  <div class="wx-content">
                    <div class="wx">
                      <img src="@/assets/ic_wechat.png" alt="" class="wx-img" />
                    </div>
                    <div class="wx-tit">关注甲第微信公众号</div>
                  </div>
                  <div class="scaner-tit">
                    扫码关注甲第公众号，更多精彩等你发现→
                  </div>
                </div>
                <div class="scaner-right">
                  <img
                    src="@/assets/pic_weixinguwen.png"
                    alt=""
                    class="xcx-img"
                  />
                </div>
              </div>
            </div>
            <div class="lx" slot="reference">
              <div>
                <img src="@/assets/lxwm.png" alt="" class="right-pic" />
              </div>
              <div class="lx-des">联系我们</div>
            </div>
          </el-popover>
          <!-- <div class="lx">
            <div><img src="@/assets/lxwm.png" alt="" class="right-pic" /></div>
            <div class="lx-des">联系我们</div>
          </div> -->
          <el-popover placement="left" width="150" trigger="hover">
            <div class="xcx-hover">
              <div class="xcx">
                <img src="@/assets/ic_xcx.png" alt="" class="xcx-img" />
              </div>
              <div class="xcx-tit">扫码使用甲第材料商城</div>
            </div>
            <div class="pic" slot="reference">
              <div class="pics">
                <img src="@/assets/icon_xcx.png" alt="" class="right-pic" />
              </div>
            </div>
          </el-popover>
          <!-- <div class="pic">
            <div class="pics">
              <img src="@/assets/icon_xcx.png" alt="" class="right-pic" />
            </div>
          </div> -->
          <div class="pic">
            <div class="pics" @click="toTop">
              <img src="@/assets/top.png" alt="" class="right-pic" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   视频弹框 -->
    <el-dialog
      :title="VideoInfo.explan"
      :visible.sync="dialogVisible"
      @close="cancelVideo()"
      center
    >
      <div class="video-dialog">
        <video
          class="videoItems"
          width="700"
          height="500"
          controls
          :controlslist="videolists.controlslist"
          loop
        >
          <source :src="VideoInfo.url" />
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBanner,
  getAddMsg,
  getDesignTeam,
  getVideo,
  getNews,
} from "@/api/http";
import homeVideo from "@/components/homeVideo.vue";
export default {
  name: "testTss",
  components: { homeVideo },
  data() {
    return {
      // 当前索引
      currentIndex: 0,
      // 定时器
      timer: "",
      // 小圆点开关
      begin: "",
      bannerList: [],
      user: "",
      city: "",
      name: "",
      tel: "",
      area: "",
      designList: [], // 设计团队
      designIndex: 0,
      dialogVisible: false,
      videoList: [],
      videoIndex: 0,
      VideoInfo: {},
      videolists: {
        autoplay: false, // 自动播放
        controls: "controls", //操作
        controlslist: "nodownload",
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      }, //video配置
      caselist: [], // 案例
      newlist: [], // 新闻资讯
      newsList: [
        {
          content:
            "2020年一场突如其来的疫情，使被迫长期居家的住户们更加关注自身生活环境的品质，漂亮舒适的小花园化身线上流量平台和社群软件上的宠儿。“庭院设计”就这样乘着短视频、Vlog等一众新媒体的东风，走入了公众视野。造园一跃成为了拥有一方小天地的住户的潮流。",
          createTime: "2022-10-31T08:41:45.000+00:00",
          id: 1,
          title: "甲第设计：投身获客蓝海，借力流量东风",
          type: 1,
        },
        {
          content:
            "“浪花园”是一座可被回收再利用的临时性展园，位于2020深圳簕杜鹃花展的宝安展园区，占地300平方米。",
          createTime: "2022-10-31T08:41:45.000+00:00",
          id: 2,
          title: "浪花园，深圳勒杜鹃花展宝安精品花园",
          type: 1,
        },

        {
          content:
            "本文整理出一些花园常见的装饰石材，并通过对其介绍带大家走进花园材料的大门，增加大家对花园石材的区分和了解，减少对花园石材的误区，方便大家在花园装修时选择出适合的石材。",
          createTime: "2022-10-31T08:41:45.000+00:00",
          id: 3,
          title: "一对孪生兄弟——石英砖和花岗岩",
          type: 1,
        },
      ],
      headShow: false,
      scroll: 0,
      navShow: false,
      homeNav: false,
      show1: true,
      page: "home",
    };
  },
  metaInfo() {
    return {
      title: '甲第设计',
      meta: [
        { name: 'description', content: '甲第设计，国内规模最大的花园公司，提供优质的产品和服务。' },
        { name: 'keywords', content: '私家花园，花园设计施工，花园设计师，花园设计公司，庭院设计案例，国内规模最大花园设计公司' },
        // Open Graph 标签示例
        // { property: 'og:title', content: '我的Open Graph标题' },
        // { property: 'og:description', content: '我的Open Graph描述' },
        // { property: 'og:image', content: 'https://example.com/path/to/image.jpg' },
      ],
    };
  },
  created() {
    this.banner();
    // 设计团队
    this.getDesign();
    // 获取视频
    this.getVideos();
    // 新闻
    this.getNew();
    this.interval = setInterval(() => {
      this.designNext();
    }, 3000);
    // document.documentElement.scrollTop = this.$route.query.scroolTop;
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.interval);
  },
  activated() {
    if (this.scroll > 0) {
      window.scrollTo(0, this.scroll);
      this.scroll = 0;
      window.addEventListener("scroll", this.handleScroll);
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.begin) {
        return;
      }
      this.begin = true;
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 3000);
    });

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // banner
    banner() {
      const listData = {
        port: 1,
      };
      getBanner(listData)
        .then((resp) => {
          this.bannerList = resp.data.data;
        })
        .catch((err) => {
          return err;
        });
    },
    showHead() {
      // this.headShow = !this.headShow;
      this.navShow = !this.navShow;
    },
    // 设计团队
    getDesign() {
      const listData = {
        pageNum: 1,
        pageSize: 999,
        post: 0,
        port: 1,
        type: 2,
      };
      getDesignTeam(listData)
        .then((res) => {
          this.designList = res.data.data.list;
        })
        .catch((err) => {
          return err;
        });
    },
    // 新闻资讯
    getNew() {
      const listData = {
        pageNum: "",
        pageSize: "",
        type: 0,
      };
      getNews(listData)
        .then((res) => {
          this.newlist = res.data.data.newsVoList;
        })
        .catch((err) => {
          return err;
        });
    },
    // 视频
    getVideos() {
      getVideo()
        .then((res) => {
          this.videoList = res.data.data;
        })
        .catch((err) => {
          return err;
        });
    },
    // 设计师
    designPrev() {
      this.designIndex--;
      if (this.designIndex < 0) {
        this.designIndex = 0;
      }
    },
    designNext() {
      this.designIndex++;
      if (this.designIndex === this.designList.length) {
        this.designIndex = 0;
      }
    },
    // 视频
    videoPrev() {
      this.videoIndex--;
      if (this.videoIndex < 0) {
        this.videoIndex = 0;
      }
    },
    videoNext() {
      this.videoIndex++;
      if (this.videoIndex === this.videoList.length) {
        this.videoIndex = 0;
      }
    },
    go() {
      // this.timer = setInterval(() => {
      //   this.autoPlay();
      // }, 3000);
    },
    stop() {
      // clearInterval(this.timer);
      // this.timer = null;
      // this.timer = setInterval(() => {
      //   this.autoPlay();
      // }, 3000);
    },
    change(index) {
      this.currentIndex = index;
    },
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.bannerList.length - 1) {
        this.currentIndex = 0;
      }
    },
    carouseTransition() {
      this.begin = false;
    },
    submitForms() {
      let regs =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 11位手机
      if (!regs.test(this.tel)) {
        this.$message("手机号码格式不正确，请重新输入");
      } else {
        const listData = {
          area: this.area,
          city: this.city,
          name: this.name,
          phone: this.tel,
        };
        getAddMsg(listData)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message("提交成功");
              this.area = "";
              this.city = "";
              this.name = "";
              this.tel = "";
            } else {
              this.$message("提交失败");
            }
          })
          .catch((err) => {
            return err;
          });
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    // 首页
    goHome() {
      this.$router.push("/");
    },
    // 精选案例
    goCase(val) {
      this.$router.push({
        path: "/selectedCases",
        query: { activeId: val },
      });
    },
    // 设计团队
    goDesign() {
      this.$router.push("/designTeam");
    },
    // 商务团队
    goBussinessTeam() {
      this.$router.push("/bussinessTeam");
    },
    // 服务流程
    goService() {
      this.$router.push("/serviceProcess");
    },
    // 新闻中心
    goNews() {
      this.$router.push("/newsCenter");
    },
    // 加入我们
    goJoin() {
      this.$router.push("/joinUs");
    },
    // 品牌故事
    goBrand() {
      this.$router.push("/brandStory");
    },
    // 联系我们
    goContact() {
      this.$router.push("/contactUs");
    },
    //点击按钮出现弹窗播放视频
    openVideo(val) {
      if (!val) {
        this.$message.error("暂无视频");
        return;
      }

      this.VideoInfo = val;
      this.dialogVisible = true;
    },
    //关闭弹出框时，视频关闭播放
    cancelVideo() {
      this.dialogVisible = false;
    },
    opensVideo() {
      this.VideoInfo.fileName = "甲第";
      this.VideoInfo.url =
        "https://jiadi-crm.oss-cn-hangzhou.aliyuncs.com/video/1666505879000/ALIOSS_VIDEO_?Expires=1666506541&OSSAccessKeyId=STS.NTmdwXdcREUERcf69scGfZRZo&Signature=LVzPdZ%2FJmJN50ttlf8VIjPrIDKo%3D&security-token=CAIS5QF1q6Ft5B2yfSjIr5fYL83sibxz8peuUEXX0jkmb8hKtZfxjTz2IHBMe3FhB%2BgXs%2FoynG9Y6vgZlqp4UZod5YZ6mGMovPpt6gqET9frma7ctM4p6vCMHWyUFGSIvqv7aPn4S9XwY%2Bqkb0u%2B%2BAZ43br9c0fJPTXnS%2Brr76RqddMKRAK1QCNbDdNNXGtYpdQdKGHaOITGUHeooBKJVBY261ss1TMnsvnnk5PM0HeE0g2mkN1yjp%2FqP52pY%2FNrOJpCSNqv1IR0DPGRiHEIukYSpfoo0fQbqWuZ5cv2CENU84Uz2iZpHVsGGoABVlFdWFEE2q3JnUKIBzWfOUNqRlM7T3cpvAZ5HhbyTQhceCSDJ1R1Em3fQE7vy40g0rGRTwItCagpLB5Hy%2B%2BPkXVD93toMuxpcVAAhypcyLdK%2BF10yrlf7beSKGKVvmuxKnQo8HMa5tRo3AheOaLonexyaHd%2F5G0X396gDNXCjyI%3D";
      this.dialogVisible = true;
    },

    handleScroll() {
      // this.scroll =
      //   document.documentElement.scrollTop || document.body.scrollTop;
      this.scroll =
        document.documentElement && document.documentElement.scrollTop;

      if (this.scroll > 300) {
        this.navShow = true;
      } else {
        this.navShow = false;
      }
      if (this.scroll > 700 && this.scroll < 1200) {
        this.$refs.myNum.start();
        this.$refs.myNums.start();
      }
    },
    goNewsDetail(val) {
      if (val === 1) {
        this.$router.push("/newsInfoOne");
      } else if (val === 2) {
        this.$router.push("/newsInfoTwo");
      } else if (val === 3) {
        this.$router.push("/newsInfoThree");
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "@/assets/fonts/font.css";
* {
  margin: 0;
  padding: 0;
}
.header-show {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 99px;
  border-bottom: 1px solid #eeeeee;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 999;
  .logo {
    width: 150px;
    height: 42px;
    margin-top: 30px;
    margin-left: 100px;
    .logo-img {
      width: 150px;
      height: 42px;
    }
  }
  .items {
    display: flex;
    line-height: 35px;
    margin-top: 27px;
    margin-bottom: 27px;
    margin-right: 100px;
    .item {
      margin-right: 70px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .item-img {
      width: 20px;
      height: 13px;
      .more-img {
        width: 20px;
        height: 13px;
      }
    }
    .item:hover {
      cursor: pointer;
      border-bottom: 3px solid #333333;
      color: #333333;
      font-weight: bold;
      transition: all 0.3s;
    }
    .item-active {
      cursor: pointer;
      border-bottom: 3px solid #333333;
      color: #333333;
      font-weight: bold;
    }
  }
}
// 轮播
.hj-carousel {
  position: relative;
  width: 100vw;
  height: 100vh;
  .list-item {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-position: center center;
    background-size: cover;
    .intro-box {
      position: relative;
      height: 100vh;
      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 124px;
        line-height: 124px;
        border-bottom: 1px solid #eeeeee;
        position: absolute;
        background-color: #fff;
        z-index: 999;
        top: -25px;
        .logo {
          width: 150px;
          height: 42px;
          margin-top: 25px;
          margin-left: 100px;
          .logo-img {
            width: 150px;
            height: 42px;
          }
        }
        .items {
          display: flex;
          line-height: 35px;
          margin-top: 50px;
          margin-bottom: 27px;
          margin-right: 100px;
          .item {
            margin-right: 70px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .item-img {
          width: 20px;
          height: 13px;
          .more-img {
            width: 20px;
            height: 13px;
          }
        }
        .item:hover {
          cursor: pointer;
          border-bottom: 3px solid #333333;
          color: #333333;
          font-weight: bold;
          transition: all 0.3s;
        }
        .item-active {
          cursor: pointer;
          border-bottom: 3px solid #333333;
          color: #333333;
          font-weight: bold;
        }
      }
      .service-intro {
        width: 81%;
        position: absolute;
        top: 26px;
        left: 100px;
        color: #fff;
        .top {
          display: flex;
          justify-content: space-between;
          .logo-img {
            width: 166px;
            height: 48px;
          }
          .items {
            display: flex;
            line-height: 60px;
            .item {
              margin-right: 70px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fff;
              .more-img {
                width: 20px;
                height: 13px;
                margin-top: 20px;
              }
            }
          }
          .more-img {
            width: 20px;
            height: 13px;
            margin-top: 20px;
          }
        }
        h2 {
          height: 53px;
          line-height: 53px;
          font-size: 38px;
          font-weight: 500;
        }
        p {
          height: 34px;
          line-height: 34px;
          font-size: 18px;
        }
        .intro-btn {
          display: block;
          margin-top: 33px;
          width: 123px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: linear-gradient(
            360deg,
            rgba(228, 39, 53, 1) 0%,
            rgba(235, 121, 101, 1) 100%
          );
          box-shadow: 0 5px 20px 0 rgba(230, 2, 19, 0.5);
          border-radius: 5px;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  .circles-items {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    z-index: 10;
    margin: 0 auto;
    text-align: center;
    font-size: 0;
    overflow: hidden;
    li {
      width: 4px;
      height: 4px;
      float: left;
      margin-right: 30px;
      border-radius: 50%;
      background-color: #fff;
      line-height: 16px;
      cursor: pointer;
      margin-top: 20px;
      &.active {
        // background-color: rgba(230, 2, 19, 1);
        background: none;
        width: 16px;
        height: 16px;
        // line-height: 16px;
        margin-top: 14px;
        border-radius: 50%;
        // border: 1px solid #fff;
        // opacity: 0.2;
        // padding: 5px;
        background-image: url("@/assets/ic_clink.svg");
      }
    }
  }
}

.list-enter,
.list-leave-to {
  width: 0;
  height: 0;
  opacity: 0;
}

.list-leave-active,
.list-enter-active {
  transition: all 1s linear;
}
.home-content {
  // width: 1320px;
  margin: 0 auto;
}
// 第二屏
.second {
  max-width: 100%;
  height: 790px;
  background-image: url("@/assets/secondbg.png");
  background-repeat: repeat;
  .second-content {
    display: flex;
    padding-top: 128px;
    max-width: 1314px;
    margin: 0 auto;
  }
  .second-left {
    // margin-left: 183px;
    text-align: left;
    .second-who {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
    }
    .second-tit {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 10px;
      margin-bottom: 27px;
      .little {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .second-line {
      width: 100px;
      height: 8px;
      background-color: #c0b4b4;
      margin-bottom: 41px;
    }
    .contents {
      margin-left: 46px;
      max-width: 490px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 21px;
    }
    .more {
      width: 150px;
      line-height: 50px;
      text-align: center;
      background: #7f7f7f;
      border: 1px solid #dedede;
      margin-left: 46px;
      margin-top: 27px;
      display: flex;
      cursor: pointer;
      transition: all 0.2s;
      .more-tit {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
        margin-left: 33px;
      }
      .more-img {
        width: 22px;
        height: 8px;
        margin-top: 23px;
        margin-left: 6px;
      }
    }
    .more:hover {
      background-color: #000;
      transition: all 0.2s;
    }
  }
  .second-right {
    width: 50%;
    // margin-right: 183px;
    width: 664px;
    margin-left: 94px;
    .right-content {
      width: 664px;
      height: 470px;

      .right-img {
        width: 664px;
        height: 470px;
        position: relative;
        // position: absolute;
        // left: 800px;
      }
      .right-bf {
        width: 80px;
        height: 80px;
        position: absolute;
        // margin-top: -200px;
        z-index: 99;
        left: 65%;
        // top: 200px;
        padding-top: 185px;
        // position: relative;
        // right: 475px;
        // top: 170px;
      }
    }
    .rightList {
      display: flex;
      justify-content: space-around;
      background-color: #fff;
      .rightItmes {
        margin: 26px 48px 26px 32px;
        .year {
          font-size: 30px;
          font-family: DINCond-Black, DINCond;
          font-weight: 900;
          color: #333333;
          line-height: 35px;
        }
        .years {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
        .des {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
      }
    }
  }
}
// 第三屏
.third {
  max-width: 100%;
  height: 953px;
  background-image: url("@/assets/thirdbg.png");
  background-repeat: repeat;
  .design {
    text-align: center;
    margin-top: 80px;
    font-size: 26px;
    font-weight: normal;
    color: #d5d5d5;
    line-height: 31px;
    letter-spacing: 10px;
  }
  .design-tit {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #333333;
    line-height: 56px;
    letter-spacing: 10px;
    margin-top: -20px;
  }
  .third-content {
    display: flex;
    justify-content: center;
    margin: 70px 0 80px;
    .left {
      .box {
        position: relative;
        width: 321px;
        height: 321px;
        overflow: hidden;
      }
      .box_img {
        width: 321px;
        height: 321px;
        position: absolute;
        margin-left: -160px;
        transition: all 0.5s;
      }

      .box:hover .box_img {
        transform: scale(1.05);
        transition: all 0.5s;
        cursor: pointer;
      }
      .inside {
        overflow: hidden;
        width: 321px;
        height: 321px;
        position: absolute;
        transition: all 0.5s;
        letter-spacing: 1px;
      }

      .inside:hover {
        background-color: rgb(0, 0, 0, 0.3);
        opacity: 0.5;
        transition: all 0.5s;
        color: #ffffff;
        cursor: pointer;
      }

      .word {
        margin-top: 320px;
        transition: all 0.5s;
        margin-bottom: 10px;
        text-align: center;
        color: #ffffff;
      }
      .line {
        width: 26px;
        margin: 0 auto;
        height: 4px;
        background-color: #fff;
        margin-top: 1px;
      }

      .box:hover .word {
        color: #ffffff;
        margin-top: 136px;
        transition: all 0.5s;
        cursor: pointer;
      }
      .bot {
        margin-top: 10px;

        .bot-img {
          width: 321px;
          height: 321px;
        }
      }
    }
    .wbox {
      position: relative;
      width: 321px;
      height: 652px;
      overflow: hidden;
    }
    .wbox_img {
      width: 321px;
      height: 652px;
      position: absolute;
      margin-left: -160px;
      transition: all 0.5s;
    }

    .wbox:hover .wbox_img {
      transform: scale(1.05);
      transition: all 0.5s;
      cursor: pointer;
    }
    .winside {
      overflow: hidden;
      width: 321px;
      height: 652px;
      position: absolute;
      transition: all 0.5s;
      letter-spacing: 1px;
    }

    .winside:hover {
      background-color: rgb(0, 0, 0, 0.3);
      opacity: 0.5;
      transition: all 0.5s;
      color: #ffffff;
      cursor: pointer;
    }

    .wword {
      margin-top: 652px;
      transition: all 0.5s;
      margin-bottom: 10px;
      text-align: center;
      color: #ffffff;
    }
    .wline {
      width: 26px;
      margin: 0 auto;
      height: 4px;
      background-color: #fff;
      margin-top: 1px;
    }

    .wbox:hover .wword {
      color: #ffffff;
      margin-top: 302px;
      transition: all 0.5s;
      cursor: pointer;
    }
    .middle {
      margin-left: 10px;
      // margin-right: 10px;
      width: 321px;
      height: 652px;

      .middle-img {
        width: 321px;
        height: 652px;
      }
    }
    .right-con {
      margin-left: 10px;
      .hbox {
        position: relative;
        width: 652px;
        height: 321px;
        overflow: hidden;
      }
      .hbox_img {
        width: 652px;
        height: 321px;
        position: absolute;
        margin-left: -326px;
        transition: all 0.5s;
      }

      .hbox:hover .hbox_img {
        transform: scale(1.05);
        transition: all 0.5s;
        cursor: pointer;
      }
      .hinside {
        overflow: hidden;
        width: 652px;
        height: 321px;
        position: absolute;
        transition: all 0.5s;
        letter-spacing: 1px;
      }

      .hinside:hover {
        background-color: rgb(0, 0, 0, 0.3);
        opacity: 0.5;
        transition: all 0.5s;
        color: #ffffff;
        cursor: pointer;
      }

      .hword {
        margin-top: 320px;
        transition: all 0.5s;
        margin-bottom: 10px;
        text-align: center;
        color: #ffffff;
      }
      .hline {
        width: 26px;
        margin: 0 auto;
        height: 4px;
        background-color: #fff;
        margin-top: 1px;
      }

      .hbox:hover .hword {
        color: #ffffff;
        margin-top: 132px;
        transition: all 0.5s;
        cursor: pointer;
      }
      .tops {
        .top-img {
          width: 652px;
          height: 321px;
        }
      }
      .bot {
        display: flex;
        margin-top: 4px;
        .box {
          position: relative;
          width: 321px;
          height: 321px;
          overflow: hidden;
        }
        .box_img {
          width: 321px;
          height: 321px;
          position: absolute;
          margin-left: -160px;
          transition: all 0.5s;
        }

        .box:hover .box_img {
          transform: scale(1.05);
          transition: all 0.5s;
          cursor: pointer;
        }
        .inside {
          overflow: hidden;
          width: 321px;
          height: 321px;
          position: absolute;
          transition: all 0.5s;
          letter-spacing: 1px;
        }

        .inside:hover {
          background-color: rgb(0, 0, 0, 0.3);
          opacity: 0.5;
          transition: all 0.5s;
          color: #ffffff;
          cursor: pointer;
        }

        .word {
          margin-top: 320px;
          transition: all 0.5s;
          margin-bottom: 10px;
          text-align: center;
          color: #ffffff;
        }
        .line {
          width: 26px;
          margin: 0 auto;
          height: 4px;
          background-color: #fff;
          margin-top: 1px;
        }

        .box:hover .word {
          color: #ffffff;
          margin-top: 132px;
          transition: all 0.5s;
          cursor: pointer;
        }
        .bot-left {
          margin-top: 5px;

          .bot-img {
            width: 321px;
            height: 321px;
          }
        }

        .bot-right {
          margin-left: 10px;
          margin-top: 5px;
          .bot-img {
            width: 321px;
            height: 321px;
          }
        }
      }
    }
  }
}
// 第四屏
.fourth {
  max-width: 100%;
  height: 820px;
  background-image: url("https://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/videobackground.png");
  background-repeat: repeat;
  background-size: cover;

  .fourth-con {
    display: flex;
    justify-content: space-between;
    max-width: 1314px;
    margin: 0 auto;
    .fourth-left {
      // margin-left: 183px;
      display: flex;
      .video {
        width: 112px;
        height: 399px;
        font-size: 100px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #ffffff;
        line-height: 115px;
        letter-spacing: 10px;
        writing-mode: vertical-lr;
        margin: 150px 0 271px 0;
      }
      .case {
        margin-top: 191px;
        .top {
          width: 112px;
          // height: 318px;
          font-size: 100px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #ffffff;
          line-height: 115px;
          letter-spacing: 10px;
          writing-mode: vertical-lr;
        }
        .tit {
          width: 28px;
          height: 100px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 5px;
          margin-top: 18px;
          writing-mode: vertical-lr;
          margin-left: 30px;
        }
      }
    }
    .fourth-right {
      margin: 171px;
      .right-img {
        width: 921px;
        height: 500px;
        position: relative;
        // position: absolute;
        // left: 800px;
      }
      .right-bf {
        width: 80px;
        height: 80px;
        position: absolute;
        // margin-top: -200px;
        z-index: 99;
        left: 60%;
        // top: 200px;
        padding-top: 185px;
      }
      .explan {
        width: 892px;
        height: 70px;
        line-height: 70px;
        position: absolute;
        text-align: left;
        margin-top: -497px;
        padding-left: 30px;
        z-index: 99;
        // background-color: #000;
        background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        opacity: 0.8;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 800;
        color: #ffffff;
      }
      .video-bot {
        display: flex;
        justify-content: space-between;
        text-align: center;
        float: right;
        margin-top: 35px;
        .left {
          width: 40px;
          height: 40px;
          line-height: 40px;
          background: rgba(255, 255, 255, 0);
          border-radius: 25px;
          opacity: 0.9;
          border: 1px solid #eeeeee;
          .left-img {
            width: 18px;
            height: 8px;
          }
        }
        .proess {
          line-height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 20px;
          margin-right: 20px;
        }
        .right {
          width: 40px;
          height: 40px;
          line-height: 40px;
          background: rgba(255, 255, 255, 0);
          border-radius: 25px;
          opacity: 0.9;
          border: 1px solid #eeeeee;
          .right-img {
            width: 18px;
            height: 8px;
          }
        }
      }
    }
  }
}
// 第五屏
.fifth {
  max-width: 100%;
  height: 1162px;
  background-color: #fff;
  .new-tit {
    margin-top: 80px;
    font-size: 26px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #d5d5d5;
    line-height: 31px;
    letter-spacing: 10px;
  }
  .new-media {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 56px;
    letter-spacing: 10px;
    margin-top: -16px;
  }
  .jd-des {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 71px;
  }
  .contents {
    display: flex;
    justify-content: space-between;
    max-width: 1314px;
    margin: 0 auto;
    .media-left {
      .media {
        display: flex;
        .box {
          position: relative;
          width: 502px;
          height: 352px;
          overflow: hidden;
        }
        .box_img {
          width: 502px;
          height: 352px;
          position: absolute;
          margin-left: -251px;
          transition: all 0.5s ease 0s;
          color: red;
        }

        .box:hover .box_img {
          // transform: scale(1.05);
          background: #000;
          transition: all 0.5s ease 0s;
          cursor: pointer;
          z-index: 99;
          -webkit-filter: blur(60px);
          filter: blur(60px);
        }
        .inside {
          overflow: hidden;
          width: 502px;
          height: 352px;
          position: absolute;
          transition: all 0.5s ease 0s;
          letter-spacing: 1px;
          opacity: 0;
        }
        .inside:hover {
          background-color: #000;
          opacity: 0.4;
          z-index: 100;
          transition: all 0.5s ease 0s;
          color: #ffffff;
          // opacity: 1;
        }

        .word {
          margin-top: 160px;
          transition: all 0.5s;
          margin-bottom: 10px;
          text-align: center;
          color: #ffffff;
          opacity: 0;
          .word-tit {
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .word-num {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 15px;
          }
          .word-line {
            width: 330px;
            margin: 0 auto;
            height: 1px;
            background-color: #c8c8c8;
            margin-top: 13px;
            margin-bottom: 18px;
          }
          .word-content {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            max-width: 360px;
            margin: 0 auto;
            text-align: center;
          }
          // display: none;
          // opacity: 0;
        }

        .box:hover .word {
          color: #ffffff;
          margin-top: 110px;
          transition: all 0.5s ease 0s;
          cursor: pointer;
          opacity: 1;
          .word-tit {
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .word-num {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 15px;
          }
          .word-line {
            width: 330px;
            margin: 0 auto;
            height: 1px;
            background-color: #c8c8c8;
            margin-top: 13px;
            margin-bottom: 18px;
          }
          .word-content {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            max-width: 360px;
            margin: 0 auto;
            text-align: center;
          }
        }
        .img-right {
          margin-left: 78px;
        }
        .media-pic {
          width: 502px;
          height: 352px;
        }
        .logo {
          display: flex;
          justify-content: center;
          margin-bottom: 45px;
          margin-top: 13px;
          .media-logo {
            width: 30px;
            height: 30px;
          }
          .media-des {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 28px;
            margin-left: 6px;
          }
        }
      }
    }
    .media-right {
      font-size: 120px;
      font-family: Arial-BoldItalicMT, Arial;
      font-weight: normal;
      color: #efefef;
      line-height: 138px;
      letter-spacing: 20px;
      writing-mode: vertical-lr;
    }
  }
}
// 第六屏
.sixth {
  max-width: 100%;
  height: 756px;
  background-image: url("https://jdqny.com/image/deisgnGroup.png");
  // background-image: url("http://jiadi-web-private.oss-cn-hangzhou.aliyuncs.com/fixed/1667352371000");
  background-size: cover;
  background-repeat: repeat;
  .new-tit {
    padding-top: 80px;
    font-size: 26px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #666666;
    line-height: 31px;
    letter-spacing: 10px;
  }
  .new-media {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 56px;
    letter-spacing: 10px;
    margin-top: -16px;
  }
  .jd-des {
    width: 841px;
    margin: 0 auto;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d0d0d0;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 80px;
  }
  // content
  .design-content {
    display: flex;
    // margin: 70px 183px 0;
    width: 1314px;
    margin: 0 auto;
    background-color: #fff;
    justify-content: space-between;
    z-index: 99;
    position: relative;
    .design-left {
      margin: 120px 114px 30px 90px;
      text-align: left;
      width: 924px;
      .design-name {
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
      }
      .design-work {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
      .design-des {
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; // 想要超出三行显示 就把这里改成3就好了
        word-break: break-all; /* break-all(允许在单词内换行。) */
        height: 200px;
      }
      .design-bot {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // margin-top: 160px;
        .more {
          width: 150px;
          line-height: 50px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #dedede;
          display: flex;
          cursor: pointer;
          transition: all 0.2s;
          .more-tit {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 50px;
            margin-left: 33px;
          }
          .more-img {
            width: 22px;
            height: 8px;
            margin-top: 23px;
            margin-left: 6px;
          }
        }
        .more:hover {
          background-color: #000;
          transition: all 0.2s;
          color: #fff;
          .more-tit {
            color: #fff;
          }
        }
        .pre-next {
          display: flex;
          justify-content: space-between;
          text-align: center;
          margin-right: 144px;
          float: right;
          .left {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background: rgba(255, 255, 255, 0);
            border-radius: 25px;
            opacity: 0.9;
            border: 1px solid #eeeeee;
            .left-img {
              width: 18px;
              height: 8px;
            }
          }
          .right {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background: rgba(255, 255, 255, 0);
            border-radius: 25px;
            opacity: 0.9;
            border: 1px solid #eeeeee;
            margin-left: 5px;
            .right-img {
              width: 18px;
              height: 8px;
            }
          }
        }
      }
    }
    .design-right {
      width: 390px;
      height: 490px;
      .designer {
        width: 390px;
        height: 490px;
      }
    }
  }
}
// 第七屏
.seventh {
  // max-width: 100%;
  width: 100vw;
  height: 100vh;
  .bg-img {
    display: flex;
    justify-content: space-between;
    position: relative;
    .box {
      position: relative;
      // width: 16.66vw;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
    .box_img {
      width: 100%;
      height: 100vh;
      // height: 820px;
      position: absolute;
      // margin-left: -180px;
      transition: all 0.8s;
      left: 0;
    }

    .box:hover .box_img {
      transform: scale(1.05);
      transition: all 0.8s;
      cursor: pointer;
    }
    .inside {
      overflow: hidden;
      // width: 16.66vw;
      width: 100%;
      margin: 0 auto;
      height: 820px;
      position: absolute;
      transition: all 0.8s;
      letter-spacing: 1px;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
    }

    .inside:hover {
      transition: all 0.8s;
      background-image: linear-gradient(
        rgba(64, 89, 146, 0.8),
        rgba(64, 89, 146, 0)
      );
      color: #ffffff;
      cursor: pointer;
      text-align: center;
    }
    .indide-mark-img {
      margin-top: 420px;
      width: 31px;
      height: 31px;
      transition: all 0.8s ease 0s;
    }
    .word {
      transition: all 0.8s ease 0s;
      margin-bottom: 10px;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }
    .words {
      margin-top: 420px;
      transition: all 2s ease 0s;
      margin-bottom: 10px;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
    }
    .words-content {
      max-width: 80%;
      margin: 0 auto;
    }
    .box:hover .indide-mark-img {
      margin-top: 306px;
      transition: all 0.8s;
      cursor: pointer;
      text-align: center;
    }
    .box:hover .word {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      margin-top: 6px;
      transition: all 0.8s;
      cursor: pointer;
    }

    .box:hover .words {
      color: #ffffff;
      margin-top: 10px;
      font-size: 14px;
      transition: all 0.8s;
      cursor: pointer;
      text-align: center;
    }
    .bg-item {
      // width: 16.66%;
      height: 820px;
    }
  }
  .seven-content {
    margin-top: -800px;

    .seven-des {
      position: absolute;
      left: 36%;
      .new-tit {
        padding-top: 80px;
        font-size: 26px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #ffffff;
        line-height: 31px;
        letter-spacing: 10px;
        opacity: 0.3;
      }
      .new-media {
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 56px;
        letter-spacing: 10px;
        margin-top: -16px;
      }
      .jd-des {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        margin-top: 20px;
      }
    }

    .mark {
      position: absolute;

      width: 100%;
      display: flex;
      // justify-content: space-around;
      .mark-item {
        margin-top: 500px;
        margin-bottom: -100px;
        width: 16.5%;
        // height: 820px;
        color: #fff;
        .items-tit {
          bottom: -1000px;
        }
      }
      .mark-img:nth-child(1) {
        width: 31px;
        height: 31px;
      }
      .mark-img:nth-child(2) {
        width: 31px;
        height: 31px;
      }
      .mark-img:nth-child(3) {
        width: 31px;
        height: 31px;
      }
      .mark-img:nth-child(4) {
        width: 31px;
        height: 31px;
      }
      .mark-img:nth-child(5) {
        width: 31px;
        height: 31px;
      }
      .mark-img:nth-child(6) {
        width: 31px;
        height: 31px;
      }
      .mark-item-hover {
        height: 800px;
        margin-top: 400px;
        width: 16.5%;
        color: #fff;
        .items-tit {
          bottom: 0;
        }
      }
    }
  }
}
// 第八屏
.eighth {
  max-width: 100%;
  height: 621px;
  background-color: #fafafa;
  .eighth-tit {
    text-align: center;
    padding-top: 80px;
    font-size: 26px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #d5d5d5;
    line-height: 31px;
    letter-spacing: 10px;
  }
  .eighth-des {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 56px;
    letter-spacing: 10px;
    margin-top: -16px;
  }
  .eighth-content {
    display: flex;
    justify-content: space-between;
    // margin: 80px 183px;
    text-align: left;
    max-width: 1314px;
    margin: 0 auto;
    overflow: hidden;
    max-height: 180px;
    margin-top: 80px;
    margin-bottom: 80px;
    .eighth-item {
      text-align: left;
      margin-left: 18px;
      cursor: pointer;
      .date {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
      .title {
        margin-top: 10px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #666666;
        line-height: 25px;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; // 想要超出三行显示 就把这里改成3就好了
        word-break: break-all; /* break-all(允许在单词内换行。) */
      }
      .lines {
        width: 418px;
        height: 1px;
        background-color: #d8d8d8;
        cursor: pointer;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
      .lines:hover {
        width: 418px;
        height: 1px;
        background-color: #666666;
      }
      .detail {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        max-width: 418px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; // 想要超出三行显示 就把这里改成3就好了
        word-break: break-all; /* break-all(允许在单词内换行。) */
      }
    }
  }
  .more {
    width: 150px;
    margin: 0 auto;
    line-height: 50px;
    text-align: center;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #dedede;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    .more-tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 50px;
      margin-left: 33px;
    }
    .more-img {
      width: 22px;
      height: 8px;
      margin-top: 23px;
      margin-left: 6px;
    }
  }
  .more:hover {
    background-color: #000;
    transition: all 0.2s;
    color: #fff;
    .more-tit {
      color: #fff;
    }
  }
}
// 第九屏
.ninth {
  max-width: 100%;
  height: 612px;
  background-color: #ffffff;
  .ninth-tit {
    text-align: center;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 56px;
    margin-top: 90px;
  }
  .ninth-tel {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-top: 20px;
  }
  .forms {
    // 去掉el-input自带边框
    display: flex;
    justify-content: center;
    max-width: 1314px;
    margin: 0 auto;
    // margin-top: 80px;
    // margin-bottom: 60px;
    .forms-item {
      margin-top: 80px;
      margin-bottom: 60px;
    }
    .el-input__inner {
      border: none !important;

      box-shadow: none !important;

      padding: 0px; //前边边距去掉
    }
    ._input {
      border: 0px; /*去除所有边框*/
      outline: none; /*去除选中时出现的边框*/
      border-bottom-color: #d8d8d8; /*下边框颜色*/
      border-bottom-left-radius: 0px; /*下左边框的圆角为0px*/
      border-bottom-right-radius: 0px; /*下右边框的圆角为0px*/
      border-bottom-width: 0.2px; /*下边框的宽度*/
      border-bottom-style: solid; /*下边框的样式 solid-->实线*/
      color: #000;
      font-size: 16px;
      width: 542px;
      margin: 10px;
      line-height: 40px;
    }
  }
  //   background: #000;
  .sub-btn {
    display: flex;
    justify-content: center;
    width: 270px;
    margin: 0 auto;
    height: 50px;
    background: #050505;
    border: 1px solid #dedede;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .sub-btn:hover {
    background-color: #363636;
    transition: all 0.2s;
  }
}
// 第十屏
.tenth {
  max-width: 100%;
  height: 480px;
  background-color: #050505;
  display: flex;
  .tenth-left {
    // margin: 80px 114px 83px 183px;
    width: 1314px;
    margin: 0 auto;
    margin-top: 114px;
    .tenth-des {
      display: flex;
      justify-content: space-between;
      text-align: left;
      .address {
        color: #ffffff;
        .address-tit {
          color: #ffffff;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        .address-site {
          color: #ffffff;
          font-size: 14px;
          margin-bottom: 14px;
        }
      }
      .tel {
        margin-left: 94px;
        color: #ffffff;
        font-size: 14px;
        margin-top: 58px;
        .tel-item {
          margin-bottom: 14px;
        }
      }
      .rightImg {
        display: flex;
        .attention {
          margin-left: 129px;
          margin-bottom: 22px;
          .attention-tit {
            color: #ffffff;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 30px;
          }
          .attention-img {
            width: 90px;
            height: 90px;
            .bot-pic {
              width: 90px;
              height: 90px;
            }
          }
          .attention-des {
            margin-top: 10px;
            color: #ffffff;
            font-size: 12px;
          }
        }
        .media {
          margin-left: 50px;
          margin-top: 58px;
          .media-img {
            width: 90px;
            height: 90px;
            .bot-pic {
              width: 90px;
              height: 90px;
            }
          }
          .media-des {
            margin-top: 10px;
            color: #ffffff;
            font-size: 12px;
          }
        }
        .mt-img {
          margin-left: 50px;
          margin-top: 58px;
          width: 90px;
          height: 90px;
          .bot-pic {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
    .tenth-router {
      display: flex;
      border-top: 1px solid #999999;
      // opacity: 0.3;
      color: #ffffff;
      .bot-logo {
        width: 166px;
        height: 39px;
        margin-top: 40px;
        .bot-logo-img {
          width: 150px;
          height: 42px;
        }
      }
      .router-item {
        color: #ffffff;
        font-size: 12px;
        margin-left: 40px;
        margin-top: 51px;
      }
    }
  }
  .tenth-right {
    position: fixed;
    // margin: 119px 40px 85px 0;
    right: 40px;
    bottom: 85px;

    .lx {
      width: 44px;
      height: 128px;
      background: #ffffff;
      box-shadow: 8px 8px 14px 0px rgba(50, 0, 6, 0.06);
      border-radius: 22px;
      border: 2px solid #ffffff;
      cursor: pointer;
      .right-pic {
        width: 18px;
        height: 18px;
        margin-top: 10px;
      }
      .lx-des {
        width: 14px;
        margin: 0 auto;
        height: 80px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
    .lx:hover {
      background-color: #f8f8f8;
    }
    .pic {
      width: 44px;
      height: 44px;
      background: #ffffff;
      box-shadow: 8px 8px 14px 0px rgba(50, 0, 6, 0.06);
      border-radius: 50%;
      border: 2px solid #ffffff;
      margin-top: 10px;
      cursor: pointer;
      .pics {
        position: relative;
        /* left: 50%; */
        top: 25%;
        .right-pic {
          width: 24px;
          height: 24px;
        }
      }
    }
    .pic:hover {
      background-color: #f8f8f8;
    }
  }
}
.video-dialog {
  display: flex;
  justify-content: center;
  text-align: center;
  // max-width: 80%;
  // width: 900px;
  min-width: 800px;
  margin: 0 auto;
  .videoItems {
    flex: 1;
    // padding: 40px;
    // width: 30%;
    // position: relative;
    // border: 1px solid #efefef;
  }
}

.dropdown {
  width: 160px;
  text-align: center;

  .dropdown-items {
    color: #666666;
  }
  .dropdown-items:hover {
    color: #333333;
    background-color: #f8f8f8;
  }
}
.content-hover {
  margin-top: 20px;
  .content-left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    .content-tel {
      margin-left: 16px;
      .tel {
        display: flex;
        .head {
          width: 18px;
          height: 18px;
          .head-img {
            width: 18px;
            height: 18px;
          }
        }
        .tel-tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-left: 5px;
          margin-bottom: 12px;
        }
      }
      .tel-des {
        font-size: 14px;
        font-family: DIN-BoldItalic, DIN;
        font-weight: normal;
        color: #666666;
        margin-bottom: 10px;
      }
    }
    .content-time {
      margin-right: 24px;
      .time-des {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 13px;
      }
      .tel-des {
        font-size: 14px;
        font-family: DIN-BoldItalic, DIN;
        font-weight: normal;
        color: #666666;
        margin-bottom: 10px;
      }
    }
  }
  .content-right {
    display: flex;
    justify-content: space-between;
    .scaner-left {
      margin-top: 22px;
      margin: 16px;
      .wx-content {
        display: flex;
        .wx {
          width: 18px;
          height: 18px;
          .wx-img {
            width: 18px;
            height: 18px;
          }
        }
        .wx-tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-left: 6px;
        }
      }
      .scaner-tit {
        margin-top: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .scaner-right {
      width: 110px;
      height: 110px;
      .xcx-img {
        width: 110px;
        height: 110px;
      }
    }
  }
}
.xcx-hover {
  margin-top: 16px;
  .xcx {
    width: 118px;
    height: 118px;
    margin-left: 16px;
    .xcx-img {
      width: 118px;
      height: 118px;
      background-size: cover;
    }
  }
  .xcx-tit {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 12px;
    text-align: center;
  }
}
.beian {
  text-align: right;
  margin-top: -34px;
  .beian-font {
    color: #ccc;
    text-decoration: none;
    font-size: 12px;
  }
  .beian-font:hover {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
  }
}
</style>
